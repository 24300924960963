<template>
  <div>
    <EditorFrame v-if="hb" :hb="hb" :audioInfo="audioInfo">
      <div style="display:flex;">
        <EditorSider
          :activeIndex.sync="currentIndex"
          :len="audioLinesMap.length"
          :modifiable="false"
        />
        <ContentManage :audioLines="audioLinesMap[currentIndex]" />
      </div>
      <template v-slot:buttons>
        <Button
          class="center-block-btn"
          type="primary"
          @click="postFinalCheck(0)"
          :disabled="isDisable"
        >确认无误，提交</Button>
        <DeleteHbButton :hb="hb" class="center-block-btn" :disabled="isDisable" />
      </template>
      <template v-slot:right-side>
        <Tabs value="name2">
          <TabPane label="编辑状态" name="name2">
            <EditStatus :hb="hb" />
          </TabPane>
          <TabPane label="版本记录" name="name1">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>

<script>
import axios from "./components/axios";
import {
  factory as getAudioLines,
  assemble as generateAudioLines,
} from "./components/resolve4audio";
import editorMixin from "../views/editorMixin";
// TODO 这个烦人的mixin
import voiceKeyProvideMixin from "./components/voiceKeyProvideMixin";
import { getHbTaskView } from "../api/hbService";
// TODO 依赖了外界的hbService！
import TransFn from "./components/baidu-translate";
export default {
  data() {
    return {
      hb: null,
      audioInfo: null,
      taskInfo: {},
      audioLinesMap: [[]],
      isDisable: false, // 开关
    };
  },
  methods: {
    //获取任务详情
    initTaskView() {
      let { taskId, processId, bizId, contentType } = this.$route.query;
      let params = { taskId, processId, bizId, contentType };
      this.$Spin.show();
      getHbTaskView(params, () => {
        this.$Spin.hide();
      }).then((res) => {
        this.hb = res.content;
        this.hb.taskName = res.taskName;
        this.taskInfo = res.taskInfo;
        this.audioLinesMap = getAudioLines(this.hb);
        this.initVoiceSexConfig(this.hb, this.audioLinesMap);
      });
    },
    //6审最终审发布
    async postFinalCheck(e) {
      let hb = this.hb;
      hb.pass = e;
      this.isDisable = true;

      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0,
      });

      this.hb.audioMap = generateAudioLines(this.audioLinesMap);
      if (/^en/.test(hb.contentType)) {
        let transList = Object.values(this.hb.audioMap).reduce(
          (arr, item) => arr.concat(item),
          []
        );

        let str = transList.reduce(
          (preStr, item) => preStr + "\n" + item.content,
          ""
        );
        let buf = (await TransFn(str)).trans_result;
        transList.forEach((item, ind) => {
          item.translation = buf[ind].dst;
        });
      }

      axios({
        method: "post",
        url: "/common/content/finalCheck",
        data: hb,
      })
        .then(() => {
          this.$Message.success("提交成功");
          this.isDropTask = false;
          setTimeout(() => {
            this.$router.back();
          }, 500);
        })
        .catch((err) => {
          this.$Message.warning("[后台错误]" + err);
        })
        .finally(() => {
          msg();
          this.isDisable = false;
        });
    },
  },
  mounted() {
    this.currentIndex = 0;
    this.initTaskView();
  },
  mixins: [editorMixin, voiceKeyProvideMixin],
};
</script>

<style>
</style>